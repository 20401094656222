import {
	ApiFormattedResponseData,
	ApiPaginatedResponse,
	ApiQueryOptions,
	Attachment,
	StringSignature,
	UUID,
} from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { User } from '@/types/users';
import { Partner } from './PartnerApi';
import { UserVoucherPassenger } from './UserVoucherPassengerApi';
import { Voucher } from './VoucherApi';
import { Location } from './LocationApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UserVoucherFilters } from '@/pages/userVouchers/UserVouchers';
import { trim } from 'lodash';
import { formatDateForApi } from '@/utils/utilities';
import EntityApi from './EntityApi';
import httpClient from './httpClient';

export enum UserVoucherStatus {
	PENDING,
	ACCEPTED,
	USED,
	MISSING_DATA,
}

export interface UserVoucher {
	id: UUID;
	expirationDate: string;
	locations: Location[];
	user?: User;
	passengers: UserVoucherPassenger[];
	status: UserVoucherStatus;
	createdAt: string;
	image?: Attachment;
	voucherNumber?: string;
	voucherCode?: string;
	partner?: Partner;
	voucher?: Voucher;
}

class UserVoucherApi extends EntityApi<UserVoucher> {
	constructor() {
		super(ApiQueryKey.userVouchers);
		this.setVoucher = this.setVoucher.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
	}

	async setVoucher({
		userVoucherId,
		voucherId,
	}: {
		userVoucherId: UUID;
		voucherId: UUID;
	}) {
		const response = await httpClient.patch<UserVoucher>(
			`${this.queryKey}/${userVoucherId}`,
			{
				voucher: { id: voucherId },
			}
		);

		return response.data;
	}

	async changeStatus({
		userVoucherId,
		status,
	}: {
		userVoucherId: UUID;
		status: UserVoucherStatus;
	}) {
		const response = await httpClient.patch<UserVoucher>(
			`${this.queryKey}/${userVoucherId}/change_status`,
			{
				status,
			}
		);

		return response.data;
	}

	async getFilteredPaginated({
		queryKey,
	}: QueryFunctionContext<
		[ApiQueryKey, ApiQueryOptions<UserVoucher>, UserVoucherFilters]
	>): Promise<ApiFormattedResponseData<UserVoucher>> {
		const params: StringSignature = {
			page: queryKey[1].page,
			limit: queryKey[1].limit,
			sortBy: `${queryKey[1].sortBy.key}:${queryKey[1].sortBy.order}`,
			search: trim(queryKey[1].search),
		};

		for (const key in params) {
			if (params[key] === '') delete params[key];
		}

		if (queryKey[2]?.isExpired)
			params['filter.expirationDate'] = `$lte:${formatDateForApi(new Date())}`;

		if (queryKey[2].locations.length)
			params['filter.locations.id'] = `$in:${queryKey[2].locations.join(',')}`;

		if (queryKey[2].vouchers.length)
			params['filter.voucher.id'] = `$in:${queryKey[2].vouchers.join(',')}`;

		if (queryKey[2].voucherCategory)
			params['filter.voucher.voucherCategory.id'] = queryKey[2].voucherCategory;

		const response = await httpClient.get<ApiPaginatedResponse<UserVoucher>>(
			queryKey[0],
			{
				params,
			}
		);

		return {
			data: response.data.data,
			totalPages: response.data.meta.totalPages,
		};
	}
}

export default new UserVoucherApi();
