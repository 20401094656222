export enum AppPaths {
	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',

	DASHBOARD = 'dashboard',

	PRODUCT_CATEGORIES = 'product-categories',
	PRODUCTS = 'products',
	VOUCHERS = 'vouchers',
	VOUCHER_CATEGORIES = 'voucher-categories',
	USER_VOUCHERS = 'user-vouchers',
	ORDERS = 'orders',

	DISCOUNT_CODES = 'discount-codes',

	LOCATIONS = 'locations',
	STARTING_POINTS = 'starting-points',
	PARTNERS = 'partners',
	SHIPPING_METHODS = 'shipping-methods',

	ADMINS = 'admins',
	USERS = 'users',
	CREWMEN = 'crewmen',
	PILOTS = 'pilots',

	CYLINDERS = 'cylinders',
	BALLOONS = 'balloons',

	FLIGHTS = 'flights',
	FLIGHT_PASSENGERS = 'flight-passengers',
	PILOT_EXPENSES = 'pilot-expenses',

	ROLES = 'roles',
	USER_LOGS = 'user-logs',
	SETTINGS = 'settings',
}
