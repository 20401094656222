import { Role } from '@/api/RoleApi';
import { Attachment, UUID } from './types';
import { Balloon } from '@/api/BalloonApi';

export enum UserType {
	ADMIN = 'admin',
	USER = 'user',
	PILOT = 'pilot',
	CREWMAN = 'crewman',
}

export enum UserStatus {
	INACTIVE,
	ACTIVE,
	SUSPENDED,
}

interface BaseUser {
	id: UUID;
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	status: UserStatus;
	createdAt: string;
	userType: UserType;
	role?: Role;
	phoneNumber: string;
}

export interface UserPilotStats {
	flightsAmount: number;
	flightsHours: number;
	externalFlightsHours: number;
}

export interface Admin extends Omit<BaseUser, 'phoneNumber'> {
	hasOwnPassword: boolean;
	userType: UserType.ADMIN;
}

export interface User extends Omit<BaseUser, 'role'> {
	cartId: string;
	userType: UserType.USER;
}

export interface Pilot extends BaseUser, UserPilotStats {
	userType: UserType.PILOT;
	pilotData: {
		id?: UUID;
		position: string;
		drivingLicenseCategory: string;
		pilotLicenseType: string;
		pilotLicenseNumber: string;
		radioPermitNumber: string;
		opcExpirationDate: string;
		trainingExpirationDate: string;
		firstAidExpirationDate: string;
		permissionExpirationDate: string;
		additionalInformation: string;
		weight: number;
	};
	balloons: Balloon[];
	address: string;
	postalCode: string;
	city: string;
	signature?: Attachment;
}

export interface Crewman extends BaseUser, UserPilotStats {
	userType: UserType.CREWMAN;
	crewmanData: {
		id?: UUID;
		position: string;
		drivingLicenseCategory: string;
		jobCode: string;
		experience: string;
		additionalInformation: string;
		weight: number;
	};
}

export type AppUser = Admin | Pilot | Crewman;
