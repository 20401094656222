import { flatten, pick } from 'lodash';
import { isAxiosError } from 'axios';
import { ApiError, AppError, ErrorCode } from '@/types/ErrorCodes';
import { formatDistance } from 'date-fns';
import { BaseEntity } from '@/api/EntityApi';
import { Language } from '@/types/types';
import { ShippingMethod, ShippingMethodStatus } from '@/api/ShippingMethodApi';
import { VoucherPermission } from '@/api/VoucherApi';
import { DiscountCodeType } from '@/api/DiscountCodeApi';
import { UserVoucherStatus } from '@/api/UserVoucherApi';
import { Flight, FlightStatus, PilotAcceptanceStatus } from '@/api/FlightApi';
import { OrderStatus } from '@/api/OrderApi';
import { PaymentStatus } from '@/api/PaymentApi';
import { Product, ProductType } from '@/api/ProductApi';
import { ComboboxItemGroup } from '@mantine/core';
import { NotificationQueueStatus } from '@/api/StatsApi';
import { PilotExpense } from '@/api/PilotExpenseApi';
import { FlightPassengerStatus } from '@/api/FlightPassengerApi';
import {
	PilotExpensesForm,
	getInitialExpsnesesForm,
} from '@/store/slices/pilotExpensesSlice';
import { pl } from 'date-fns/locale';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';
import { UserLogAction, UserLogEntity } from '@/api/UserLogApi';

export const priceFormatter = new Intl.NumberFormat('PL-pl', {
	style: 'currency',
	currency: 'PLN',
	currencyDisplay: 'code',
});

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

export function randomDate(start: Date, end: Date) {
	return moment(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	).toDate();
}

export function formatDateForApi(date: moment.MomentInput) {
	return moment(date).format('YYYY-MM-DD');
}

export function formatDateForPresentation(
	date: moment.MomentInput = new Date()
) {
	const momentDate = moment(date);
	if (!momentDate.isValid()) return '-';

	return moment(date).format('DD/MM/YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput = new Date()
) {
	return moment(date).format('HH:mm');
}

export function formatExactDate(date: moment.MomentInput = new Date()) {
	return `${formatDateForPresentation(date)}, ${formatTimeForPresentation(
		date
	)}`;
}

export function sortByDate(
	dateA: moment.MomentInput,
	dateB: moment.MomentInput,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function sortByName(
	nameA = '',
	nameB = '',
	order: 'asc' | 'desc' = 'asc'
) {
	return order === 'asc'
		? nameA.localeCompare(nameB)
		: nameB.localeCompare(nameA);
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function decrementDate(date: moment.MomentInput) {
	return moment(date).subtract(1, 'day').toDate();
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export const formatPrice = (value = 0, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat('pl', {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'code',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export const isAppError = (error: unknown): error is AppError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as AppError).message
	);
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export const fontColorForBackground = (backgroundColorHex = '#ffffff') => {
	return [
		0.299 * parseInt(backgroundColorHex.slice(1, 3), 16),
		0.587 * parseInt(backgroundColorHex.slice(3, 5), 16),
		0.114 * parseInt(backgroundColorHex.slice(5, 7), 16),
	].reduce((a, b) => a + b) /
		255 >
		0.65
		? '#495057'
		: '#ffffff';
};

export const formatFullName = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) return `${firstName} ${lastName}`;
	if (!firstName && !lastName) return '-';
	return firstName || lastName || '-';
};

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function formatDateFromNow(date: Date) {
	return formatDistance(date, new Date(), {
		locale: pl,
		includeSeconds: true,
		addSuffix: true,
	});
}

export function isDefaultLanguage(language: Language): language is 'PL' {
	return language === APP_CONFIG.DEFAULT_LANGUAGE;
}

export function fileSizeToMB(size: number | string) {
	return Math.round((Number(size) / 1000000 + Number.EPSILON) * 100) / 100;
}

export function extractId<TData extends BaseEntity>(entity: TData) {
	return pick(entity, 'id');
}

export function mapVoucherPermisionName(permision: VoucherPermission) {
	switch (permision) {
		case VoucherPermission.weekdayAfternoon:
			return 'Wieczór w tygodniu';
		case VoucherPermission.weekdayMorning:
			return 'Poranek w tygodniu';
		case VoucherPermission.weekend:
			return 'Weekendy (rano i wieczorem)';
	}
}

export function mapDiscountCodeTypeName(type: DiscountCodeType) {
	switch (type) {
		case DiscountCodeType.PERCENTAGE:
			return 'Procent (%)';
		case DiscountCodeType.PLN_PER_PRODUCT:
			return 'Kwota (PLN) do każdego produktu';
		case DiscountCodeType.PLN:
			return 'Kwota (PLN)';
	}
}

export function mapUserVoucherStatusColor(status: UserVoucherStatus) {
	return ['warning', 'success', 'danger', 'dark'][status];
}

export function mapUserVoucherStatusName(status: UserVoucherStatus) {
	switch (status) {
		case UserVoucherStatus.PENDING:
			return 'Oczekuje na akceptację';
		case UserVoucherStatus.ACCEPTED:
			return 'Przyjęty';
		case UserVoucherStatus.USED:
			return 'Wykorzystany';
		case UserVoucherStatus.MISSING_DATA:
			return 'Oczekuje na uzupełnienie';
	}
}

export function mapProductTypeName(productType: ProductType) {
	switch (productType) {
		case ProductType.ACCESSORY:
			return 'Dodatek';
		case ProductType.VOUCHER:
			return 'Voucher';
	}
}

export function mapFlightStatusName(status: FlightStatus) {
	switch (status) {
		case FlightStatus.CANCELLED:
			return 'Odwołany';
		case FlightStatus.DISABLED:
			return 'Wyłączony';
		case FlightStatus.FINISHED:
			return 'Zakończony';
		case FlightStatus.PLANNED:
			return 'Zaplanowany';
		case FlightStatus.ACCEPTED:
			return 'Potwierdzony';
	}
}

export function mapPilotAcceptanceStatusName(status: PilotAcceptanceStatus) {
	switch (status) {
		case PilotAcceptanceStatus.PENDING:
			return 'Oczekuje na akceptację pilota';
		case PilotAcceptanceStatus.ACCEPTED:
			return 'Zaakceptowany przez pilota';
		case PilotAcceptanceStatus.REJECTED:
			return 'Odrzucony przez pilota';
	}
}

export function mapOrderStatusName(status: OrderStatus) {
	switch (status) {
		case OrderStatus.PENDING:
			return 'Oczekująca';
		case OrderStatus.ACCEPTED:
			return 'Zrealizowana';
		case OrderStatus.REJECTED:
			return 'Odrzucona';
	}
}

export function mapFlightPassengerStatusName(status: FlightPassengerStatus) {
	switch (status) {
		case FlightPassengerStatus.REGISTERED:
			return 'Zapisany';
		case FlightPassengerStatus.ACCEPTED:
			return 'Zaakceptowany';
		case FlightPassengerStatus.REJECTED:
			return 'Odrzucony';
		case FlightPassengerStatus.RESIGNED:
			return 'Rezygnacja';
		case FlightPassengerStatus.ACCEPTED_TO_ANOTHER_FLIGHT:
			return 'Zakwalifikowany na inny termin';
		case FlightPassengerStatus.SUSPENDED:
			return 'Zawieszony';
	}
}

export function mapShippingMethodStatusName(status: ShippingMethodStatus) {
	switch (status) {
		case ShippingMethodStatus.ACTIVE:
			return 'Aktywna';
		case ShippingMethodStatus.INACTIVE:
			return 'Nieaktywna';
	}
}

export function isUserVoucherNearExpiration(date: moment.MomentInput) {
	const diff = moment(date).diff(moment(), 'days');
	return diff < APP_CONFIG.USER_VOUCHER_EXPIRATION_TRESCHOLD;
}

export function formatShippingMethodName(shippingMethod: ShippingMethod) {
	const name = shippingMethod.name;
	if (shippingMethod.price)
		return `${name}  (+${formatPrice(shippingMethod.price / 100)})`;

	return name;
}

export function mapPaymentStatusName(status: PaymentStatus) {
	switch (status) {
		case PaymentStatus.PENDING:
			return 'W trakcie';
		case PaymentStatus.FAILURE:
			return 'Odrzucona';
		case PaymentStatus.SUCCESS:
			return 'Przyjęta';
	}
}

export function mapNotificationQueueStatusName(
	status: NotificationQueueStatus
) {
	switch (status) {
		case NotificationQueueStatus.PENDING:
			return 'W trakcie';
		case NotificationQueueStatus.SENT:
			return 'Wysłana';
		case NotificationQueueStatus.FAILED:
			return 'Odrzucona';
	}
}

export function groupProductsForSelect(products: Product[]) {
	const grouped: ComboboxItemGroup[] = [
		{
			group: mapProductTypeName(ProductType.VOUCHER),
			items: products
				.filter((product) => product.type === ProductType.VOUCHER)
				.map((product) => ({
					value: product.id,
					label: product.name,
				})),
		},
		{
			group: mapProductTypeName(ProductType.ACCESSORY),
			items: products
				.filter((product) => product.type === ProductType.ACCESSORY)
				.map((product) => ({
					value: product.id,
					label: product.name,
				})),
		},
	];

	return grouped;
}

export function formatFlightName(flight: Flight) {
	return `${flight.location.name} ${formatExactDate(flight.startDate)}`;
}

export function mapPilotExpensedToExpensesForm(
	pilotExpenses: PilotExpense[]
): PilotExpensesForm {
	const form = getInitialExpsnesesForm();

	for (const expense of pilotExpenses) {
		form[expense.type][expense.id] = {
			...pick(expense, [
				'date',
				'description',
				'document',
				'dietsAmount',
				'flightsAmount',
				'from',
				'method',
				'readinessAmount',
				'to',
				'location',
				'dayTime',
				'dateFrom',
				'dateTo',
			]),
			amount: expense.amount / 100,
		};
	}

	return form;
}

export function mapUserLogEntityName(type: UserLogEntity) {
	switch (type) {
		case UserLogEntity.ADMIN:
			return 'Administrator';
		case UserLogEntity.BALLOON:
			return 'Balon';
		case UserLogEntity.CLIENT:
			return 'Klient';
		case UserLogEntity.CREWMAN:
			return 'Załogant';
		case UserLogEntity.DISCOUNT_CODE:
			return 'Kod rabatowy';
		case UserLogEntity.FLIGHT:
			return 'Lot';
		case UserLogEntity.ORDER:
			return 'Zamówienie';
		case UserLogEntity.PARTNER:
			return 'Partner';
		case UserLogEntity.PILOT:
			return 'Pilot';
		case UserLogEntity.PRODUCT:
			return 'Produkt';
		case UserLogEntity.PRODUCT_CATEGORY:
			return 'Kategoria produktu';
		case UserLogEntity.USER_VOUCHER:
			return 'Voucher użytkownika';
		case UserLogEntity.VOUCHER:
			return 'Rodzaj voucheru';
	}
}
