import { UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { Flight } from './FlightApi';
import { Pilot } from '@/types/users';
import { PilotExpensesForm } from '@/store/slices/pilotExpensesSlice';
import { omitBy } from 'lodash';
import { AxiosResponse } from 'axios';
import EntityApi from './EntityApi';
import httpClient from './httpClient';

export enum PilotExpenseMethod {
	CASH = 'Gotówka',
	BLIK = 'Blik',
	BANK_TRANSFER = 'Przelew',
	CARD = 'Karta',
}

export enum PilotExpenseType {
	FUEL_EXPENSES,
	HOTEL_EXPENSES,
	FOOD_EXPENSES,
	SALARY_EXPENSES,
	OTHER_EXPENSES,
	CLIENT_REVENUE,
	OTHER_REVENUE,
}

export interface PilotExpense {
	id: UUID;
	type: PilotExpenseType;
	flight: Flight;
	pilot: Pilot;
	date: string;
	amount: number;
	method: PilotExpenseMethod;
	document: string;
	from: string;
	to: string;
	description: string;
	dietsAmount: number;
	flightsAmount: number;
	readinessAmount: number;
	dateFrom: string;
	dateTo: string;
	location: string;
	dayTime: string;
}

class PilotExpenseApi extends EntityApi<PilotExpense> {
	constructor() {
		super(ApiQueryKey.pilotExpenses);
		this.createFlightExpenses = this.createFlightExpenses.bind(this);
		this.updateFlightExpenses = this.updateFlightExpenses.bind(this);
	}

	async createFlightExpenses({
		flightId,
		pilotId,
		form,
	}: {
		flightId: UUID;
		pilotId: UUID;
		form: PilotExpensesForm;
	}) {
		const payload = {
			flight: { id: flightId },
			pilot: { id: pilotId },
		};

		const requests: Promise<AxiosResponse<PilotExpense>>[] = [];

		form.forEach((record, idx) => {
			for (const row of Object.values(record)) {
				requests.push(
					httpClient.post<PilotExpense>(this.queryKey, {
						...payload,
						...omitBy(row, (val) => !val),
						amount: row.amount * 100,
						type: idx,
					})
				);
			}
		});

		await Promise.all(requests);

		return 1;
	}

	async updateFlightExpenses({
		flightId,
		pilotId,
		removedExpenses,
		form,
	}: {
		flightId: UUID;
		pilotId: UUID;
		removedExpenses: UUID[];
		form: PilotExpensesForm;
	}) {
		const payload = {
			flight: { id: flightId },
			pilot: { id: pilotId },
		};

		const requests = [];

		for (const removed of removedExpenses) {
			requests.push(super.delete(removed));
		}

		form.forEach((record, idx) => {
			for (const [id, row] of Object.entries(record)) {
				row.isNew
					? requests.push(
							httpClient.post<PilotExpense>(this.queryKey, {
								...payload,
								...omitBy(row, (val) => !val),
								amount: row.amount * 100,
								type: idx,
							})
					  )
					: requests.push(
							super.update({
								...omitBy(row, (val) => !val),
								id: id as UUID,
								amount: row.amount * 100,
							})
					  );
			}
		});

		await Promise.all(requests);

		return 1;
	}
}

export default new PilotExpenseApi();
