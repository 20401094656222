import { configureStore } from '@reduxjs/toolkit';
import { StoreKey } from '@/configs/storeKeys';
import appReducer from './slices/appSlice';
import pilotExpensesReducer from './slices/pilotExpensesSlice';

export const store = configureStore({
	reducer: {
		[StoreKey.APP]: appReducer,
		[StoreKey.PILOT_EXPENSES]: pilotExpensesReducer,
	},
	devTools: import.meta.env.DEV,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
