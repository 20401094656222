import { UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import EntityApi from './EntityApi';

export enum ShippingMethodStatus {
	ACTIVE,
	INACTIVE,
}

export interface ShippingMethod {
	id: UUID;
	name: string;
	price: number;
	status: ShippingMethodStatus;
}

class ShippingMethodApi extends EntityApi<ShippingMethod> {
	constructor() {
		super(ApiQueryKey.shippingMethods);
	}
}

export default new ShippingMethodApi();
