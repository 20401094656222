import { Attachment, UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { VoucherCategory } from './VoucherCategoryApi';
import { VoucherForm } from '@/pages/vouchers/CreateVoucher';
import httpClient from './httpClient';
import EntityApi from './EntityApi';
import AppApi from './AppApi';

export type VoucherPermissionPriority = 1 | 2 | 3 | 4;

export enum VoucherPermission {
	weekdayMorning = 'weekdayMorning',
	weekdayAfternoon = 'weekdayAfternoon',
	weekend = 'weekend',
}

export interface Voucher {
	id: UUID;
	name: string;
	description: string;
	expirationDays: number;
	permissions: Record<
		VoucherPermission,
		{
			includes: boolean;
			priority: VoucherPermissionPriority;
		}
	>;
	voucherCategory: VoucherCategory;
	image?: Attachment;
}

class VoucherApi extends EntityApi<Voucher> {
	constructor() {
		super(ApiQueryKey.vouchers);
		this.createWithImage = this.createWithImage.bind(this);
		this.updateWithImage = this.updateWithImage.bind(this);
	}

	async createWithImage(payload: VoucherForm) {
		let image: Attachment | undefined = undefined;

		if (payload.file) image = await AppApi.createAttachment(payload.file);

		const response = await httpClient.post<Voucher>(this.queryKey, {
			...payload,
			image,
			voucherCategory: { id: payload.voucherCategory },
		});

		return response.data;
	}

	async updateWithImage(payload: VoucherForm) {
		let image: Attachment | undefined = undefined;

		if (payload.file) image = await AppApi.createAttachment(payload.file);

		const response = await httpClient.patch<Voucher>(
			`${this.queryKey}/${payload.id}`,
			{
				...payload,
				image,
				voucherCategory: { id: payload.voucherCategory },
			}
		);

		return response.data;
	}
}

export default new VoucherApi();
