import { Admin, Pilot } from '@/types/users';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import {
	ApiFormattedResponseData,
	ApiPaginatedResponse,
	ApiQueryOptions,
	StringSignature,
	UUID,
} from '@/types/types';
import { UserLogsFilters } from '@/pages/userLogs/UserLogs';
import { QueryFunctionContext } from '@tanstack/react-query';
import { formatDateForApi } from '@/utils/utilities';
import httpClient from './httpClient';
import EntityApi from './EntityApi';

export enum UserLogEntity {
	PRODUCT_CATEGORY = 'product-category',
	PRODUCT = 'product',
	VOUCHER = 'voucher',
	USER_VOUCHER = 'user-voucher',
	ORDER = 'order',
	PARTNER = 'partner',
	DISCOUNT_CODE = 'discount-code',
	FLIGHT = 'flight',
	BALLOON = 'balloon',
	CLIENT = 'client',
	ADMIN = 'admin',
	PILOT = 'pilot',
	CREWMAN = 'crewman',
}

export enum UserLogAction {
	CREATE = 'create',
	UPDATE = 'update',
	DELETE = 'delete',
}

export type UserLogData = Record<
	string,
	{
		previous?: any;
		current?: any;
	}
>;

export interface UserLog {
	id: UUID;
	createdAt: string;
	updatedAt: string;
	user: Pilot | Admin | null;
	action: UserLogAction;
	entity: UserLogEntity;
	entityId?: UUID;
	data: UserLogData;
}

class UserLogApi extends EntityApi<UserLog> {
	constructor() {
		super(ApiQueryKey.userLogs);
	}

	async getFilteredPaginated({
		queryKey,
	}: QueryFunctionContext<
		[ApiQueryKey, Omit<ApiQueryOptions<UserLog>, 'search'>, UserLogsFilters]
	>): Promise<ApiFormattedResponseData<UserLog>> {
		const params: StringSignature = {
			page: queryKey[1].page,
			limit: queryKey[1].limit,
			sortBy: `${queryKey[1].sortBy.key}:${queryKey[1].sortBy.order}`,
			'filter.entity': queryKey[2].entity,
		};

		if (queryKey[2].createdAt[0] && queryKey[2].createdAt[1])
			params['filter.createdAt'] = `$btw:${formatDateForApi(
				queryKey[2].createdAt[0]
			)},${formatDateForApi(queryKey[2].createdAt[1])}`;

		const response = await httpClient.get<ApiPaginatedResponse<UserLog>>(
			queryKey[0],
			{
				params,
			}
		);

		return {
			data: response.data.data,
			totalPages: response.data.meta.totalPages,
		};
	}
}

export default new UserLogApi();
