import { ReactNode } from 'react';
import {
	DefaultOptions,
	Mutation,
	MutationCache,
	Query,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { useTypedDispatch } from '@/store/hooks';
import { setErrorCode } from '@/store/slices/appSlice';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HttpStatusCode } from 'axios';
import { isAppError as checkIfAppError } from '@/utils/utilities';
import APP_CONFIG from '@/configs/appConfig';

export const QueryProvider = ({ children }: { children: ReactNode }) => {
	const dispatch = useTypedDispatch();

	const handleQueryError = (
		error: unknown,
		query: Query<unknown, unknown, unknown>
	) => {
		if (query.meta?.ignoreErrors) return;

		const isAppError = checkIfAppError(error);
		if (!isAppError) return;

		dispatch(setErrorCode(error.status.toString()));

		if (import.meta.env.DEV)
			console.error(`Error stack: ${error.stack.join('\n')}`);
	};

	const handleMutationError = (
		error: unknown,
		variables: unknown,
		context: unknown,
		mutation: Mutation<unknown, unknown, unknown>
	) => {
		if (mutation.meta?.ignoreErrors) return;

		const isAppError = checkIfAppError(error);
		if (!isAppError) return;

		if (error.status >= HttpStatusCode.InternalServerError)
			dispatch(setErrorCode(error.status.toString()));

		if (import.meta.env.DEV)
			console.error(`Error stack: ${error.stack.join('\n')}`);
	};

	const defaultOptions: DefaultOptions = {
		queries: {
			staleTime: APP_CONFIG.QUERY_STALE_TIME,
			gcTime: APP_CONFIG.QUERY_CACHE_TIME,
			retry: APP_CONFIG.QUERY_RETRY,
		},
		mutations: {
			retry: false,
		},
	};

	const queryCache = new QueryCache({
		onError: handleQueryError,
	});

	const mutationCache = new MutationCache({
		onError: handleMutationError,
	});

	const queryClient = new QueryClient({
		defaultOptions,
		queryCache,
		mutationCache,
	});

	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
		</QueryClientProvider>
	);
};
