import { UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { Product } from './ProductApi';
import EntityApi from './EntityApi';

export enum DiscountCodeType {
	PERCENTAGE,
	PLN_PER_PRODUCT,
	PLN,
}

export interface DiscountCode {
	id: UUID;
	type: DiscountCodeType;
	code: string;
	value: number;
	maxUsage: number;
	usages: number;
	products: Product[];
	canCombine: boolean;
	expiresAt: string;
}

class DiscountCodeApi extends EntityApi<DiscountCode> {
	constructor() {
		super(ApiQueryKey.discountCodes);
	}
}

export default new DiscountCodeApi();
